<template>
  <div 
    class="view-claim-auth" 
    @keyup="onKeyUp">
    <div 
      class="view-claim-auth__body" 
      v-if="!done">
      <!-- Header -->
      <header class="view-claim-auth__header">
        <h1 class="view-claim-auth__title">{{ title }}</h1>
        <p class="view-claim-auth__descr">{{ description }}</p>
      </header>

      <!-- Error -->
      <section 
        class="view-claim-auth__login flow" 
        v-if="hasError">
        <ui-banner
          :description="claimError.description"
          :title="claimError.title"
          :intent="$pepper.Intent.DANGER"
        />
      </section>

      <!-- Login -->
      <section 
        class="view-claim-auth__login flow"
        v-if="!user.hasWallet">
        <!-- OptInWallet -->
        <forms-checkbox
          :size="$pepper.Size.L"
          @change="onOptInWalletChange"
          v-model="optInWalletTc">
          <div class="view-claim-auth__terms-content">
            <span
              class="view-claim-auth__terms"
              v-html="$t('user-portal.user_wallet_terms_label')"
            ></span>

            <span
              class="view-claim-auth__terms-link"
              @click.stop.prevent="() => showWalletTerms = true"
              v-html="$t('user-portal.user_wallet_terms_link')"
            ></span>
          </div>
        </forms-checkbox>

        <modal-wallet-pins
          :errors="errors"
          :visible="showPins"
          @confirm="onPinsConfirm"
          @close="onPinsClose"
          v-if="showPins"
        />
      </section>

      <!-- Confirm button -->
      <section class="view-claim-auth__login flow">
        <p class="view-claim-auth__label">{{ $t('user-portal.user_claim_explanations') }}</p>

        <actions-button
          appearance="primary"
          class="fill"
          :disabled="loading || !optInWalletTc"
          :loading="loading"
          @click="onConfirm"
        >{{ confirmLabel }}</actions-button>
      </section>
    </div>

    <!-- Success -->
    <div 
      class="view-claim-auth__body" 
      v-if="done">
      <ui-banner
        class="layout-claim__banner"
        :intent="$pepper.Intent.SUCCESS"
        :title="successTitle">
        <template #description>
          <div class="layout-claim__banner-descr">
            <p v-html="successDescr"></p>
          
            <actions-button
              class="layout-claim__banner-btn"
              icon-post="open"
              :href="{ name: 'sayl-user-portal.wallet' }"
              :size="$pepper.Size.S"
            >{{ $t('user-portal.nft_claim_go_to_wallet') }}</actions-button>
          </div>
        </template>
      </ui-banner>
    </div>

    <modal-wallet-terms
      :visible="showWalletTerms"
      @close="() => showWalletTerms = false"
      v-if="showWalletTerms"
    />
  </div>
</template>

<script>
import SaylStore from '@/bootstrap/stores'

import MixinErrors from '@/helpers/errors'
import MixinClaimError from '@/modules/claim/helpers/errors.js'
import MixinRouteLeave from '@/modules/claim/helpers/router-leave.js'
import MixinSelfClaim from '@/modules/claim/helpers/self-claim.js'

import ModalWalletTerms from '@/components/modals/wallet-terms.vue'
import UiBanner from '@/components/ui/banner.vue'

import ModalWalletPins from '@/modules/claim/components/modals/wallet-pins'


export default {
  name: 'ClaimLoginView',

  components: {
    // FormsPins,
    ModalWalletPins,
    ModalWalletTerms,
    UiBanner,
  },

  inject: [
    '$user',
    '$nft',

    '$redirect'
  ],

  mixins: [
    MixinClaimError,
    MixinErrors,
    MixinRouteLeave,
    MixinSelfClaim,
  ],

  data() {
    return {
      done: false,
      errors: {},

      loading: false,
      optInWalletTc: false,

      timer: 60,
      debounceRedirect: null, 

      showWalletTerms: false,
      
      showPins: false,

      pins: {
        pin: [],
        pin_confirmation: []
      },
    }
  },

  computed: {
    confirmLabel() {
      if(this.isPeerTransfer) {
        return this.$t('user-portal.nft_peer_transfer_action_confirm')
      }

      return this.$t('user-portal.action_claim')
    },

    description() {
      if(this.isPeerTransfer) {
        return this.$t('user-portal.nft_peer_transfer_descr')
      }

      return this.$t('user-portal.user_claim_descr')
    },

    successDescr() {
      if(this.isPeerTransfer) {
        return this.$t('user-portal.nft_peer_transfer_awaiting_final_confirmation_description', { timer: this.timer })
      }

      return this.$t('user-portal.nft_claim_success_description', { timer: this.timer })
    },

    successTitle() {
      if(this.isPeerTransfer) {
        return this.$t('user-portal.nft_peer_transfer_awaiting_final_confirmation')
      }

      return this.$t('user-portal.nft_claim_success')
    },

    title() {
      if(this.isPeerTransfer) {
        return this.$t('user-portal.nft_peer_transfer_title')
      }

      return this.$t('user-portal.user_claim_title')
    },

    transfer() {
      if(this.isPeerTransfer) {
        return this.$nft.peerTransfer
      }
    
      return this.$nft.transfer || null
    },

    user() {
      return this.$user.user
    },

    walletIsCustodial() {
      let bootstrap = SaylStore.bootstrap()
      let venly = bootstrap.venly

      return venly.is_custodial
    }
  },

  methods: {
    async onConfirm() {
      try {
        this.loading = true
        this.errors = {}

        let item = {
          opt_in_wallet_tc: this.optInWalletTc,
        }

        if(!this.user.hasWallet && !this.walletIsCustodial) {
          item.pin = this.pins.pin.join('')
          item.pin_confirmation = this.pins.pin_confirmation.join('')
        }
        
        if(this.isPeerTransfer) {
          item.nft_peer_transfer_id = this.$basil.get(this.transfer, 'id', null)
        } else {
          item.nft_transfer_id = this.$basil.get(this.transfer, 'id', null)
        }

        await this.claim(item)
        await this.$user.init({})

        this.done = true
        this.onTimer()
      } catch(e) {
        if(this.isPeerTransfer && e.status && e.status === 422) {
          this.claimError = {
            status: e.status,
            title: this.$t(`user-portal.nft_peer_transfer_error`),
            description: this.$t(`user-portal.nft_peer_transfer_id_error`),
          }
          this.hasError = true
        } else {
          this.handleErrors(e)
          this.handleClaimErrors(e)
        }
      } finally {
        this.loading = false
      }
    },

    onKeyUp(ev) {
      if(ev.code === 'Enter') {
        this.onConfirm()
      }
    },

    onOptInWalletChange() {
      if(this.optInWalletTc === true) {
        if (!this.walletIsCustodial) {
          this.showPins = true
        }
        return
      } 

      this.pins = {
        pin: [],
        pin_confirmation: []
      }
    },

    onPinsConfirm(values) {
      this.showPins = false
      this.pins = values
    },

    onPinsClose() {
      this.optInWalletTc = false
      this.showPins = false
      this.pins = {
        pin: [],
        pin_confirmation: []
      }
    },

    onTimer() {
      const decrement = () => {
        this.timer = this.timer - 1
        if(this.timer > 0) {
          setTimeout(decrement, 1000)
        } else {
          this.$router.replace({ name: 'sayl-user-portal.wallet' }).catch(() => {})
        }
      }

      setTimeout(decrement, 1000)
    }
  },

  mounted() {
    this.claimError =  {
        status: null,
        title: null,
        description: null,
    }
    this.optInWalletTc = this.user.hasWallet
    this.timer = 60
  }
}
</script>
